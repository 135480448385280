import { TitleWithLines } from '@shared/ui/titles';
import { SmartLink } from '@shared/lib/routing';
import { clsx } from 'clsx';
import { Carousel } from '@shared/ui/carousel';
import Item from '../item/item';
import styles from './list.module.scss';
import type { IPublicationOnMain } from '../../model/types';

interface IControl {
  direction: 'left' | 'right';
  onClick?: () => void;
}

function Control({ direction, onClick }: IControl) {
  return (
    <button
      onClick={onClick}
      type="button"
      aria-label={direction === 'left' ? 'предыдущий' : 'следующий'}
      className={clsx(styles.arrow, {
        [styles.left]: direction === 'left',
        [styles.right]: direction === 'right',
      })}
    />
  );
}

interface IPublicationListProps {
  list?: IPublicationOnMain[];
  href: string;
  titleText: string;
  bottomLinkText: string;
  mainDomain: string;
}

export function PublicationList({
  list,
  href,
  titleText,
  bottomLinkText,
  mainDomain,
}: IPublicationListProps) {
  if (!list?.length) return null;
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <section className={styles.root}>
          <TitleWithLines>
            <SmartLink className={styles.titleLink} href={href}>
              {titleText}
            </SmartLink>
          </TitleWithLines>
          <ul className="hidden lg:grid lg:grid-cols-3 lg:gap-6">
            {list.slice(0, 3).map((item) => (
              <li className={styles.desktopItem} key={item.id}>
                <Item item={item} rootHref={href} mainDomain={mainDomain} />
              </li>
            ))}
          </ul>
          <Carousel
            containerClass="flex! lg:hidden!"
            customLeftArrow={<Control direction="left" />}
            customRightArrow={<Control direction="right" />}
            itemClass={styles.carouselItem}
            responsive={{
              mobile: { breakpoint: { max: 430, min: 0 }, items: 1 },
              tablet: { breakpoint: { max: 768, min: 431 }, items: 2 },
              laptop: { breakpoint: { max: 1024, min: 769 }, items: 3 },
            }}
            deviceType="tablet"
            ssr
          >
            {list.map((item) => (
              <Item
                key={item.id}
                item={item}
                rootHref={href}
                mainDomain={mainDomain}
              />
            ))}
          </Carousel>
          <div className={styles.linkContainer}>
            <SmartLink className={styles.link} href={href}>
              {bottomLinkText}
            </SmartLink>
          </div>
        </section>
      </div>
    </div>
  );
}
