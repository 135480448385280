import { HtmlParser } from '@shared/html';

export function PageCustomText({
  customText,
  mainDomain,
}: {
  customText: string;
  mainDomain: string;
}) {
  return <HtmlParser data={customText} imgProps={{ mainDomain }} />;
}
