import { ArrowButton, Carousel } from '@shared/ui/carousel';
import style from './mobileSlider.module.scss';
import { IMainSlider } from '../../model/types';
import { SliderItem } from '../SliderItem';

const responsive = {
  mobile: { breakpoint: { max: 768, min: 0 }, items: 1 },
  tablet: { breakpoint: { max: 1023, min: 769 }, items: 2 },
};

interface IMobileSliderProps {
  banners: IMainSlider[];
  className: string;
  mainDomain: string;
}

export function MobileSlider({
  banners,
  className,
  mainDomain,
}: IMobileSliderProps) {
  return (
    <Carousel
      deviceType="mobile"
      responsive={responsive}
      containerClass={className}
      itemClass={style.sliderItem}
      customRightArrow={<ArrowButton direction="right" />}
      customLeftArrow={<ArrowButton direction="left" />}
      draggable={false}
      infinite
      ssr
    >
      {banners.map((banner) => (
        <SliderItem slide={banner} key={banner.id} mainDomain={mainDomain} />
      ))}
    </Carousel>
  );
}
