import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { ISeo } from '../model/types';

export function PageHelmet({
  cityName,
  title,
  seo,
  mainDomain,
  ogTitle,
  ogDescription,
  type,
  image,
  noindex = false,
}: {
  cityName: string;
  title: string;
  seo: ISeo;
  mainDomain: string;
  ogTitle?: string;
  ogDescription?: string;
  type?: string;
  image?: string;
  noindex?: boolean;
}) {
  const host = `https://${mainDomain}`;
  const { pathname } = useLocation();
  const currentPath = new URL(pathname, host).toString();
  const replacerByPattern = (field: string, h1: string) => {
    if (!field) return h1;
    const patterns = {
      city: cityName,
      H1: h1,
    };

    return Object.entries(patterns).reduce(
      (result, [key, value]) => result.replace(`{${key}}`, value),
      field,
    );
  };

  const meta = [
    {
      name: 'description',
      content: replacerByPattern(seo.description, title),
    },
    { name: 'keywords', content: replacerByPattern(seo.keywords, title) },
    {
      property: 'og:title',
      content: ogTitle ?? replacerByPattern(seo.title, title),
    },
    {
      property: 'og:description',
      content: ogDescription ?? replacerByPattern(seo.description, title),
    },
    {
      property: 'og:url',
      content: currentPath,
    },
  ];

  if (type) {
    meta.push({ property: 'og:type', content: type });
  }

  if (image) {
    meta.push({ property: 'og:image', content: `${host}/img/${image}` });
  }

  if (noindex) {
    meta.push({ name: 'robots', content: 'noindex' });
  }
  return (
    <Helmet>
      <title>{replacerByPattern(seo.title, title)}</title>
      {meta.map((m) => (
        <meta key={m.content} {...m} />
      ))}
      <link
        rel="canonical"
        href={seo.canonical ? `${host}${seo.canonical}` : currentPath}
      />
    </Helmet>
  );
}
