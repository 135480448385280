import { clsx } from 'clsx';
import { IMainSlider } from '../model/types';
import { SliderItem } from './SliderItem';
import { MobileSlider } from './mobile-slider/MobileSlider';
import { DesktopSlider } from './desktop-slider/DesktopSlider';
import style from './mainSlider.module.scss';

export function MainSlider({
  banner,
  mainDomain,
}: {
  banner?: IMainSlider[];
  mainDomain: string;
}) {
  if (!banner?.length) return null;
  const bannersWithImage = banner.filter((slide) => !!slide.image);

  if (bannersWithImage.length === 1) {
    return (
      <div className={clsx(style.container, style.single)}>
        <SliderItem slide={bannersWithImage[0]} mainDomain={mainDomain} />
      </div>
    );
  }

  return (
    <>
      <MobileSlider
        banners={bannersWithImage}
        className={clsx('flex! lg:hidden!', style.container)}
        mainDomain={mainDomain}
      />
      <DesktopSlider
        banners={bannersWithImage}
        className={clsx('hidden! lg:flex!', style.container)}
        mainDomain={mainDomain}
      />
    </>
  );
}
