import { useTranslation } from 'react-i18next';
import { TrioOnMain } from '@pages/main-page/trio-on-main';
import { Publication } from '@entities/publication';
import { useLoaderData, useRouteLoaderData } from '@shared/lib/routing';
import { PageBanner, PageCustomText, PageHelmet } from '@widgets/seoPage';
import { Fragment } from 'react';
import { Preloader } from '@shared/ui/preloader';
import { MainSlider } from '@pages/main-page/main-slider';
import { PromoBlocks } from '@pages/main-page/main-promo';
import { mainPageLoader } from '../api';
import { BlockNames } from '../model/types';

export function MainPage() {
  const { t } = useTranslation();
  const { country, city } = useRouteLoaderData('city-layout');
  const { pageData, loading } =
    useLoaderData<Awaited<ReturnType<typeof mainPageLoader>>>();

  if (loading) {
    return <Preloader />;
  }

  const { blocksData, seoPageData } = pageData;
  const renderContentBlock = ({ name, data }: (typeof blocksData)[number]) => {
    switch (name) {
      case BlockNames.stocks:
        return <TrioOnMain stocks={data} mainDomain={country.main_domain} />;
      case BlockNames.promos:
        return <PromoBlocks promoLinks={data} />;
      case BlockNames.news:
        return (
          <Publication
            mainDomain={country.main_domain}
            list={data?.map((item) => ({
              ...item,
              publication_date: item.publication_from,
            }))}
            titleText={t('Main.news')}
            bottomLinkText={t('Main.allNews')}
            href="news"
          />
        );
      case BlockNames.articles:
        return (
          <Publication
            mainDomain={country.main_domain}
            href="articles"
            titleText={t('Main.articles')}
            bottomLinkText={t('Articles.allArticles')}
            list={data}
          />
        );
      case BlockNames.slider:
        return <MainSlider banner={data} mainDomain={country.main_domain} />;
      default:
        return null;
    }
  };

  return (
    <>
      <PageBanner
        bannerData={seoPageData.banner}
        mainDomain={country.main_domain}
      />
      <PageHelmet
        cityName={city.city_name}
        mainDomain={country.main_domain}
        seo={seoPageData.seo}
        title={t('localization.Main')}
      />
      <div className="flex flex-col items-center gap-10">
        {blocksData.map((block) => (
          <Fragment key={block.name}>{renderContentBlock(block)}</Fragment>
        ))}
        <PageCustomText
          customText={seoPageData.seo.custom_text}
          mainDomain={country.main_domain}
        />
      </div>
    </>
  );
}
